import {
    scrollTo
} from 'scroll-js';
import {
    toggle
} from "slide-element";
import scrollTriggers from 'scroll-triggers';
import {
    Carousel
} from "latte-carousel";
import LazyLoad from 'vanilla-lazyload';

init();

function init() {

    uihome();
    uidropdown();
    uicycle();
    uiintroduction();

    window.addEventListener('resize', resize);
    resize();

}

function resize() {

    let descriptionlist = document.querySelectorAll('.cycle-description');

    if (descriptionlist) {

        for (let i = 0; i < descriptionlist.length; i++) {

            descriptionlist[i].style.height = 'auto';


            descriptionlist[i].classList.remove('cycle-move');


        }

    }

}

function uihome() {

    let home = document.getElementById('home-button');

    scrollTriggers([{
        el: document.getElementById('trigger-home'),
        position: 'top',
        inView: (el, options) => {
            home.classList.add('scrolled');
        },
        outOfView: (el, options) => {
            home.classList.remove('scrolled');
        }
    }]);

    home.addEventListener("click", (e) => {

        scrollTo(window, {
            top: 0,
            duration: 700,
            easing: 'ease-out'
        }).then(function() {

        });

    });

}

function uidropdown() {

    let dropdowns = document.querySelectorAll('.ui-dropdown');

    if (dropdowns.length) {

        for (let i = 0; i < dropdowns.length; i++) {

            let linkstr = dropdowns[i].getAttribute('link');
            let link = document.querySelector(linkstr);

            if (link) {

                let time = 800;

                dropdowns[i].addEventListener("click", (e) => {

                    let fsize = parseInt(window.getComputedStyle(document.body).fontSize);
                    let closed = true;

                    if (link.classList.contains('dropdown-open')) {

                        setTimeout(function() {

                            link.classList.remove('dropdown-open');
                            dropdowns[i].classList.remove('dropdown-open');

                        }, 0.1 * time);

                        closed = false;

                    }

                    toggle(link, {
                        duration: time,
                        easing: "ease-in-out",
                    }).then((isOpen) => {

                        if (isOpen) {

                            link.classList.add('dropdown-open');
                            dropdowns[i].classList.add('dropdown-open');

                        }

                    });

                    requestAnimationFrame(function() {

                        let linkrect = link.getBoundingClientRect();
                        let linkpos = closed ? linkrect.top - 0.85 * fsize : linkrect.top - window.innerHeight + 2 * fsize;
                        let scrollto = linkpos + window.scrollY;

                        scrollTo(window, {
                            top: scrollto,
                            duration: time,
                            easing: 'ease-out'
                        }).then(function() {

                        });

                    });


                });

            }

        }

    }

}

function uiintroduction() {

    let list = document.querySelectorAll('.introduction-artists-list');

    if (list.length) {

        for (let i = 0; i < list.length; i++) {

            if (list[i]) {
                let slug = list[i].getAttribute('link');
                let link = document.querySelector('.artist-wrap[link="' + slug + '"]');

                if (link) {

                    list[i].addEventListener("click", (e) => {

                        let linktop = link.getBoundingClientRect().top + window.scrollY - 10;

                        scrollTo(window, {
                            top: linktop,
                            duration: 1000,
                            easing: 'ease-out'
                        });

                    })


                }

            }

        }

    }

}

function uicycle() {

    let cyclelist = document.querySelectorAll('.cycle-wrap');

    if (cyclelist.length) {

        for (let i = 0; i < cyclelist.length; i++) {

            let cycleimages = cyclelist[i].querySelector('.cycle-entry');
            let cycleprev = cyclelist[i].querySelector('.cycle-previous');
            let cyclenext = cyclelist[i].querySelector('.cycle-next');
            let cyclealt = cyclelist[i].querySelector('.cycle-description');

            let lazy = new LazyLoad({
                container: cyclelist[i]
            });

            const options = {
                count: 1,
                move: 1,
                touch: true,
                mode: "align",
                buttons: true,
                dots: false,
                rewind: true,
                autoplay: 0,
                animation: 500,
            };

            const carousel = new Carousel(cycleimages, options);
            let cycleinit = true;

            cycleprev.addEventListener('click', function() {

                carousel.trigger("previous");

            })

            cyclenext.addEventListener('click', function() {

                carousel.trigger("next");

            })

            carousel.on("move", () => {

                if (!cycleinit) {

                    cyclealt.style.height = cyclealt.clientHeight;
                    cyclealt.classList.add('cycle-move');


                } else {

                    cycleinit = !cycleinit;

                }

            });

            carousel.on("moved", () => {

                cyclealt.style.height = cyclealt.clientHeight;

                let num = carousel.stage.currentIndex;
                let altel = carousel.itemElements[num];
                let alt = altel.getAttribute('description');

                cyclealt.innerHTML = alt;

                cyclealt.classList.remove('cycle-move');

                if (num != 0) {

                    lazy.update();

                }

            });

        }

    }

}